<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>
      <div
        class="image-cover hero-banner"
        style="background: #eff6ff url(assets/img/home-2.png) no-repeat"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-9 col-md-11 col-sm-12">
              <div class="inner-banner-text text-center">
                <p class="lead-i">
                  Over 10,000 available properties
                  <span class="badge badge-success"
                    ><i class="lni lni-thumbs-up"></i
                  ></span>
                </p>
                <h2>
                  <span class="font-normal">Find Your</span> Perfect Property.
                </h2>
              </div>
              <div
                class="full-search-2 eclip-search italian-search hero-search-radius shadow-hard mt-5"
              >
                <div class="hero-search-content">
                  <form @submit.prevent="search">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-12 b-r">
                        <div class="form-group">
                          <div class="choose-propert-type">
                            <ul>
                              <li>
                                <input
                                  id="cp-3"
                                  class="checkbox-custom"
                                  name="cpt"
                                  type="radio"
                                  v-model="search_type"
                                  value="all"
                                />
                                <label for="cp-3" class="checkbox-custom-label"
                                  >All</label
                                >
                              </li>
                              <li>
                                <input
                                  id="cp-1"
                                  class="checkbox-custom"
                                  name="cpt"
                                  type="radio"
                                  checked
                                  v-model="search_type"
                                  value="buy"
                                />
                                <label for="cp-1" class="checkbox-custom-label"
                                  >Buy</label
                                >
                              </li>
                              <li>
                                <input
                                  id="cp-2"
                                  class="checkbox-custom"
                                  name="cpt"
                                  type="radio"
                                  v-model="search_type"
                                  value="rent"
                                />
                                <label for="cp-2" class="checkbox-custom-label"
                                  >Rent</label
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-5 col-sm-12 p-0 elio">
                        <div class="form-group">
                          <div class="input-with-icon">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search Location, Listing, Category..."
                              v-model="search_term"
                              @input="getSuggestions"
                            />
                            <img src="/assets/img/pin.svg" width="20" />
                            <ul
                              id="search-suggestions"
                              v-if="search_suggestions.length > 0"
                            >
                              <li
                                v-for="(item, i) in search_suggestions"
                                :key="`suggestion-${i}`"
                              >
                                <router-link :to="{ name: 'page', params: { slug: search_type }, query: { search: item.name }}">{{ item.name }}</router-link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2 col-md-3 col-sm-12">
                        <div class="form-group">
                          <button
                            class="btn search-btn theme-bg"
                            ><i class="lni lni-search mr-2"></i> Search</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ============================ Hero Banner End ================================== -->

      <!-- ================= Explore Property ================= -->
      <section class="pt-4">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10 text-center">
              <div class="sec-heading center">
                <h2>Explore Awesome Properties</h2>
              </div>
            </div>
          </div>

          <template v-if="explore_loaded">
            <div class="row">
              <!-- Single Property -->
              <div
                class="col-lg-3 col-md-6 col-sm-12"
                v-for="(property, i) in explore"
                :key="`explore-${i}`"
              >
                <div class="property-listing property-2" style="height: 95%">
                  <div class="listing-img-wrapper">
                    <div class="list-img-slide">
                      <div class="click">
                        <div>
                          <router-link
                            :to="{
                              name: 'page',
                              params: { slug: property.slug },
                            }"
                          >
                            <span v-if="property.featured" class="is-featured"
                              >Promoted</span
                            >
                            <v-image
                              :src="property.image"
                              src-placeholder="/assets/image-loading.gif"
                              @error="property.image = '/assets/image-error.png'"
                            />
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="listing-detail-wrapper pb-0">
                    <div class="listing-short-detail-wrap">
                      <div class="listing-short-detail">
                        <span class="property-type"
                          >For {{ property.type }}</span
                        >
                        <h4 class="listing-name verified">
                          <router-link
                            :to="{
                              name: 'page',
                              params: { slug: property.slug },
                            }"
                            class="prt-link-detail"
                            >{{ property.name }}</router-link
                          >
                        </h4>
                        <!-- <p class="my-0 text-tiny text-muted">{{ property.date }}</p> -->
                      </div>
                    </div>
                  </div>
                  <div class="listing-detail-wrapper py-2">
                    <div class="listing-short-detail-wrap">
                      <div class="listing-short-detail-flex">
                        <h6 class="listing-card-info-price h5">
                          <small>KES</small> {{ property.amount }}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div class="price-features-wrapper">
                    <div class="list-fx-features mt-0">
                      <div
                        class="listing-card-info-icon"
                        v-for="(quality, j) in property.qualities"
                        :key="`quality-${(i + 1) * (j + 1)}`"
                      >
                        <div class="inc-fleat-icon">
                          <img :src="quality.icon" width="13" alt="" />
                        </div>
                        {{ quality.name }}
                      </div>
                    </div>
                  </div>

                  <div class="listing-detail-footer mt-0">
                    <div class="footer-first">
                      <div class="foot-location">
                        <img src="/assets/img/pin.svg" width="18" alt="" />{{
                          property.region
                        }}
                      </div>
                    </div>
                    <div class="footer-flex">
                      <router-link
                        :to="{
                          name: 'page',
                          params: { slug: property.slug },
                        }"
                        class="prt-view"
                        >View</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Single Property -->
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <router-link
                  :to="{ name: 'page', params: { slug: 'discover' } }"
                  class="btn btn-theme-light-2 rounded"
                  >Browse More Properties</router-link
                >
              </div>
            </div>
          </template>
          <template v-if="!explore_loaded">
            <loading />
          </template>
        </div>
      </section>
      <!-- ================================= Explore Property =============================== -->

      <!-- ============================ All Property ================================== -->
      <section class="bg-3">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10 text-center">
              <div class="sec-heading center">
                <h2>Discover Properties For Rent</h2>
              </div>
            </div>
          </div>

          <template v-if="discover_loaded">
            <div class="row list-layout">
              <!-- Single Property Start -->
              <div
                class="col-lg-6 col-md-12"
                v-for="(property, i) in discover_rent"
                :key="`discover-${i}`"
              >
                <div class="property-listing property-1">
                  <div class="listing-img-wrapper">
                    <router-link
                      :to="{
                        name: 'page',
                        params: { slug: property.slug },
                      }"
                    >
                      <img
                        :src="property.image"
                        class="img-fluid mx-auto"
                        :alt="property.name"
                      />
                    </router-link>
                  </div>

                  <div class="listing-content">
                    <div class="listing-detail-wrapper-box">
                      <div class="listing-detail-wrapper">
                        <div class="listing-short-detail">
                          <h4 class="listing-name">
                            <router-link
                              :to="{
                                name: 'page',
                                params: { slug: property.slug },
                              }"
                              >{{ property.name }}</router-link
                            >
                          </h4>
                          <span class="prt-types sale"
                            >For {{ property.type }}</span
                          >
                          <!-- <span class="my-0 text-tiny text-muted float-end">{{ property.date }}</span> -->
                        </div>
                      </div>
                      <div class="listing-detail-wrapper py-0">
                        <div class="list-price">
                          <h6 class="listing-card-info-price mb-0">
                            <small>KES</small> {{ property.amount }}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div class="price-features-wrapper">
                      <div class="list-fx-features mt-0">
                        <div
                          class="listing-card-info-icon"
                          v-for="(quality, j) in property.qualities"
                          :key="`quality-${(i + 1) * (j + 1)}`"
                        >
                          <div class="inc-fleat-icon">
                            <img :src="quality.icon" width="13" alt="" />
                          </div>
                          {{ quality.name }}
                        </div>
                      </div>
                    </div>

                    <div class="listing-footer-wrapper">
                      <div class="listing-locate">
                        <span class="listing-location"
                          ><i class="ti-location-pin"></i
                          >{{ property.region }}</span
                        >
                      </div>
                      <div class="listing-detail-btn">
                        <router-link
                          :to="{
                            name: 'page',
                            params: { slug: property.slug },
                          }"
                          class="more-btn"
                          >View</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Single Property End -->
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <router-link
                  :to="{ name: 'page', params: { slug: 'discover' } }"
                  class="btn btn-theme-light-2 rounded"
                  >Browse More Properties</router-link
                >
              </div>
            </div>
          </template>
          <template v-if="!discover_loaded">
            <loading />
          </template>
        </div>
      </section>

      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10 text-center">
              <div class="sec-heading center">
                <h2>Discover Properties For Sale</h2>
              </div>
            </div>
          </div>

          <template v-if="discover_loaded">
            <div class="row list-layout">
              <!-- Single Property Start -->
              <div
                class="col-lg-6 col-md-12"
                v-for="(property, i) in discover"
                :key="`discover-${i}`"
              >
                <div class="property-listing property-1">
                  <div class="listing-img-wrapper">
                    <router-link
                      :to="{
                        name: 'page',
                        params: { slug: property.slug },
                      }"
                    >
                      <img
                        :src="property.image"
                        class="img-fluid mx-auto"
                        :alt="property.name"
                      />
                    </router-link>
                  </div>

                  <div class="listing-content">
                    <div class="listing-detail-wrapper-box">
                      <div class="listing-detail-wrapper">
                        <div class="listing-short-detail">
                          <h4 class="listing-name">
                            <router-link
                              :to="{
                                name: 'page',
                                params: { slug: property.slug },
                              }"
                              >{{ property.name }}</router-link
                            >
                          </h4>
                          <span class="prt-types sale"
                            >For {{ property.type }}</span
                          >
                          <!-- <span class="my-0 text-tiny text-muted float-end">{{ property.date }}</span> -->
                        </div>
                      </div>
                      <div class="listing-detail-wrapper py-0">
                        <div class="list-price">
                          <h6 class="listing-card-info-price mb-0">
                            <small>KES</small> {{ property.amount }}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div class="price-features-wrapper">
                      <div class="list-fx-features mt-0">
                        <div
                          class="listing-card-info-icon"
                          v-for="(quality, j) in property.qualities"
                          :key="`quality-${(i + 1) * (j + 1)}`"
                        >
                          <div class="inc-fleat-icon">
                            <img :src="quality.icon" width="13" alt="" />
                          </div>
                          {{ quality.name }}
                        </div>
                      </div>
                    </div>

                    <div class="listing-footer-wrapper">
                      <div class="listing-locate">
                        <span class="listing-location"
                          ><i class="ti-location-pin"></i
                          >{{ property.region }}</span
                        >
                      </div>
                      <div class="listing-detail-btn">
                        <router-link
                          :to="{
                            name: 'page',
                            params: { slug: property.slug },
                          }"
                          class="more-btn"
                          >View</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Single Property End -->
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <router-link
                  :to="{ name: 'page', params: { slug: 'discover' } }"
                  class="btn btn-theme-light-2 rounded"
                  >Browse More Properties</router-link
                >
              </div>
            </div>
          </template>
          <template v-if="!discover_loaded">
            <loading />
          </template>
        </div>
      </section>
      <!-- ============================ All Featured Property ================================== -->

      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10 text-center">
              <div class="sec-heading center">
                <h2>Discover Properties For BnB</h2>
              </div>
            </div>
          </div>

          <template v-if="discover_loaded">
            <div class="row list-layout">
              <!-- Single Property Start -->
              <div
                class="col-lg-6 col-md-12"
                v-for="(property, i) in discover_bnb"
                :key="`discover-${i}`"
              >
                <div class="property-listing property-1">
                  <div class="listing-img-wrapper">
                    <router-link
                      :to="{
                        name: 'page',
                        params: { slug: property.slug },
                      }"
                    >
                      <img
                        :src="property.image"
                        class="img-fluid mx-auto"
                        :alt="property.name"
                      />
                    </router-link>
                  </div>

                  <div class="listing-content">
                    <div class="listing-detail-wrapper-box">
                      <div class="listing-detail-wrapper">
                        <div class="listing-short-detail">
                          <h4 class="listing-name">
                            <router-link
                              :to="{
                                name: 'page',
                                params: { slug: property.slug },
                              }"
                              >{{ property.name }}</router-link
                            >
                          </h4>
                          <span class="prt-types sale"
                            >For {{ property.type }}</span
                          >
                          <!-- <span class="my-0 text-tiny text-muted float-end">{{ property.date }}</span> -->
                        </div>
                      </div>
                      <div class="listing-detail-wrapper py-0">
                        <div class="list-price">
                          <h6 class="listing-card-info-price mb-0">
                            <small>KES</small> {{ property.amount }}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div class="price-features-wrapper">
                      <div class="list-fx-features mt-0">
                        <div
                          class="listing-card-info-icon"
                          v-for="(quality, j) in property.qualities"
                          :key="`quality-${(i + 1) * (j + 1)}`"
                        >
                          <div class="inc-fleat-icon">
                            <img :src="quality.icon" width="13" alt="" />
                          </div>
                          {{ quality.name }}
                        </div>
                      </div>
                    </div>

                    <div class="listing-footer-wrapper">
                      <div class="listing-locate">
                        <span class="listing-location"
                          ><i class="ti-location-pin"></i
                          >{{ property.region }}</span
                        >
                      </div>
                      <div class="listing-detail-btn">
                        <router-link
                          :to="{
                            name: 'page',
                            params: { slug: property.slug },
                          }"
                          class="more-btn"
                          >View</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Single Property End -->
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <router-link
                  :to="{ name: 'page', params: { slug: 'discover' } }"
                  class="btn btn-theme-light-2 rounded"
                  >Browse More Properties</router-link
                >
              </div>
            </div>
          </template>
          <template v-if="!discover_loaded">
            <loading />
          </template>
        </div>
      </section>
      <!-- ============================ All Featured Property ================================== -->

      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10 text-center">
              <div class="sec-heading center">
                <h2>Discover Spaces For Sharing</h2>
              </div>
            </div>
          </div>

          <template v-if="discover_loaded">
            <div class="row list-layout">
              <!-- Single Property Start -->
              <div
                class="col-lg-6 col-md-12"
                v-for="(property, i) in discover_sharespace"
                :key="`discover-${i}`"
              >
                <div class="property-listing property-1">
                  <div class="listing-img-wrapper">
                    <router-link
                      :to="{
                        name: 'page',
                        params: { slug: property.slug },
                      }"
                    >
                      <img
                        :src="property.image"
                        class="img-fluid mx-auto"
                        :alt="property.name"
                      />
                    </router-link>
                  </div>

                  <div class="listing-content">
                    <div class="listing-detail-wrapper-box">
                      <div class="listing-detail-wrapper">
                        <div class="listing-short-detail">
                          <h4 class="listing-name">
                            <router-link
                              :to="{
                                name: 'page',
                                params: { slug: property.slug },
                              }"
                              >{{ property.name }}</router-link
                            >
                          </h4>
                          <span class="prt-types sale"
                            >For {{ property.type }}</span
                          >
                          <!-- <span class="my-0 text-tiny text-muted float-end">{{ property.date }}</span> -->
                        </div>
                      </div>
                      <div class="listing-detail-wrapper py-0">
                        <div class="list-price">
                          <h6 class="listing-card-info-price mb-0">
                            <small>KES</small> {{ property.amount }}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div class="price-features-wrapper">
                      <div class="list-fx-features mt-0">
                        <div
                          class="listing-card-info-icon"
                          v-for="(quality, j) in property.qualities"
                          :key="`quality-${(i + 1) * (j + 1)}`"
                        >
                          <div class="inc-fleat-icon">
                            <img :src="quality.icon" width="13" alt="" />
                          </div>
                          {{ quality.name }}
                        </div>
                      </div>
                    </div>

                    <div class="listing-footer-wrapper">
                      <div class="listing-locate">
                        <span class="listing-location"
                          ><i class="ti-location-pin"></i
                          >{{ property.region }}</span
                        >
                      </div>
                      <div class="listing-detail-btn">
                        <router-link
                          :to="{
                            name: 'page',
                            params: { slug: property.slug },
                          }"
                          class="more-btn"
                          >View</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Single Property End -->
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <router-link
                  :to="{ name: 'page', params: { slug: 'discover' } }"
                  class="btn btn-theme-light-2 rounded"
                  >Browse More Properties</router-link
                >
              </div>
            </div>
          </template>
          <template v-if="!discover_loaded">
            <loading />
          </template>
        </div>
      </section>
      <!-- ============================ All Featured Property ================================== -->

      <call-to-action />

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  beforeMount() {
    this.fetch();
  },

  data() {
    return {
      search_term: "",
      search_type: "all",
      explore: [],
      explore_loaded: false,
      discover_loaded: false,
      discover_rent: [],
      discover_bnb: [],
      discover_sharespace: [],
      discover: [],
      search_suggestions: [],
    };
  },

  methods: {
    fetch() {
      this.explore_loaded = false;
      this.discover_loaded = false;

      this.$axios.get("/api/v1/explore").then((response) => {
        this.explore = response.data.properties;
        this.explore_loaded = true;
      });

      this.$axios.get("/api/v1/discover").then((response) => {
        this.discover_rent = response.data.properties_rent;
        this.discover_bnb = response.data.properties_bnb;
        this.discover_sharespace = response.data.properties_sharespace;
        this.discover = response.data.properties;
        this.discover_loaded = true;
      });
    },

    search() {
      this.$router.push({
        name: "page",
        params: { slug: this.search_type },
        query: {
          search: this.search_term,
        },
      });
    },

    getSuggestions() {
      if(this.search_term.length > 0) {
        this.$axios
          .get(`/api/v1/search-suggestions?term=${this.search_term}`)
          .then((response) => {
            this.search_suggestions = response.data.suggestions;
          });
      } else {
        this.search_suggestions = []
      }
    },
  },

  computed: {
    categories() {
      return this.$store.getters.getMenuCategories;
    },
  },
};
</script>
